import moment from "moment";
import phpUnserialize from "phpunserialize";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

moment.updateLocale("id", {
    months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
    ],
    monthsShort: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Ags",
        "Sep",
        "Okt",
        "Nov",
        "Des",
    ],
    weekdays: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"],
    weekdaysShort: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
    weekdaysMin: ["Mg", "Sn", "Sl", "Rb", "Km", "Jm", "Sb"],
    longDateFormat: {
        LT: "HH.mm",
        LTS: "HH.mm.ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY [pukul] HH.mm",
        LLLL: "dddd, D MMMM YYYY [pukul] HH.mm",
    },
    meridiemParse: /pagi|siang|sore|malam/,
    meridiemHour: (hour: number, meridiem: string) => {
        let result = hour;
        if (hour === 12) {
            result = 0;
        }
        if (meridiem === "pagi") return hour;
        if (meridiem === "siang") return hour >= 11 ? hour : hour + 12;
        if (meridiem === "sore" || meridiem === "malam") return hour + 12;
    },
    meridiem: (hours) => {
        if (hours < 11) return "pagi";
        if (hours < 15) return "siang";
        if (hours < 19) return "sore";
        return "malam";
    },
    calendar: {
        sameDay: "[Hari ini pukul] LT",
        nextDay: "[Besok pukul] LT",
        nextWeek: "dddd [pukul] LT",
        lastDay: "[Kemarin pukul] LT",
        lastWeek: "dddd [lalu pukul] LT",
        sameElse: "L",
    },
    invalidDate: "Tanggal tidak valid",
});

function truncateText(text: string, maxLength = 10) {
    let result = text.substring(0, maxLength);
    if (text.length > maxLength) result += "...";
    return result;
}

function objectToQueryString(obj: Record<string, string | number>) {
    const params = new URLSearchParams();
    for (const key in obj) {
        params.append(key, obj[key].toString());
    }
    return `?${params.toString()}`;
}

function showProgressService(title = "Sedang diproses", text = "Mohon tunggu") {
    return Swal.fire({
        title,
        text,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
    });
}

// biome-ignore lint/suspicious/noExplicitAny: can't be bothered to type this for now
function showNotificationServiceError(error: any) {
    let notifyMessage = "<p>Terjadi Kesalahan</p>";
    let notifyType = "error";
    let notifyTitle = "Oops...";

    if (
        error.status === 500 ||
        error.status === 404 ||
        error.status === "Forbidden"
    ) {
        notifyType = "error";
        notifyMessage = `${error.status} : ${error.statusText}`;
    } else if (error.data) {
        if (error.data.errors) {
            notifyTitle = "Perhatian";
            notifyType = "warning";
            notifyMessage =
                "<p><strong>Mohon perhatikan isian form kamu ya</strong></p></br>";
            let errorCount = 0;
            for (const key in error.data.errors) {
                const errors = error.data.errors[key];
                if (Array.isArray(errors)) {
                    for (const errorMsg of errors) {
                        errorCount++;
                        notifyMessage += `${errorCount}. ${errorMsg}<br>`;
                    }
                } else {
                    errorCount++;
                    notifyMessage += `${errorCount}. ${errors}<br>`;
                }
            }
        } else if (error.data.message) {
            notifyType = "warning";
            notifyMessage = error.data.message;
            notifyTitle = "Perhatian";
        }
    } else if (typeof error === "string") {
        notifyType = "error";
        notifyMessage = error;
    }

    return Swal.fire({
        title: notifyTitle,
        icon: notifyType,
        html: notifyMessage,
    });
}

function notifConfirmation({
    title,
    detail,
    successMessage,
    callback,
}: {
    title: string;
    detail: string;
    successMessage: string;
    callback: () => void;
}) {
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton:
                "btn btn-success bg-green-ipbt px-2 py-1 rounded-md mr-4 text-white",
            cancelButton:
                "btn btn-danger bg-red-ipbt px-2 py-1 rounded-md text-white",
        },
        buttonsStyling: false,
    });

    swalWithBootstrapButtons
        .fire({
            title: title,
            text: detail,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Ya, saya yakin!",
            cancelButtonText: "Batal!",
            reverseButtons: false,
        })
        .then((result) => {
            if (result.isConfirmed) {
                swalWithBootstrapButtons.fire(
                    "Sukses",
                    successMessage,
                    "success",
                );
                callback();
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    "Dibatalkan",
                    "tidak melakukan tindakan apapun :)",
                    "error",
                );
            }
        });
}

function successNotif(
    title = "Berhasil",
    message = "Berhasil melakukan permintaan",
) {
    Swal.fire(title, message, "success");
}

export function successNotifContent(title: string, content: string) {
    Swal.fire({
        title: title,
        html: content,
        icon: "success",
    });
}

const dateSearch = (string) => {
    const dateTime = string !== null && moment(string).format("YYYY-M-D");
    return string !== null ? dateTime : "";
};

/**
 * Pretty much just a wrapper for .find(), not sure why the previous codebase has this
 */
function matchValue<TValue>(object: { value: TValue }[], target: TValue) {
    return object.find((val) => val.value === target);
}

function formatDateTime(value: string | Date) {
    return moment(value).format("yyyy-MM-DD HH:mm:ss");
}

function formatDate(value: string | Date) {
    return moment(value).format("yyyy-MM-DD");
}

function formatDateHuman(value: string | Date) {
    return moment(value).format("DD/MM/yyyy");
}

function formatTime(value: string | Date) {
    return moment(value).format("HH:mm:ss");
}

function formatYear(value: string | Date) {
    return moment(value).format("yyyy");
}

function feedbackDataTableNotfound() {
    // Generate a random number between 1 and 10
    const randomNumber = Math.floor(Math.random() * 3) + 1;

    // Check if the random number is greater than 1
    if (randomNumber === 1) {
        return "Eits, nggak ada data di sini! Kayaknya dia lagi kabur ke tempat lain deh.";
    }
    if (randomNumber === 2) {
        return "Waduh, kok nggak ada data ya? Jangan-jangan data-nya lagi sibuk jadi influencer di tabel lain.";
    }
    if (randomNumber === 3) {
        return "Maaf ya, aku nggak nemu data yang kamu cari. Mungkin lagi galau dan ingin mencari jati diri di tabel lain.";
    }
}

function isOfflineTraining(studyMethod: number | null): boolean {
    return studyMethod === null || studyMethod === 1 || studyMethod === 3;
}

export function formatDateTraining(
    dateFrom: string | Date,
    dateEnd: string | Date,
): string {
    const from = moment(dateFrom);
    const end = moment(dateEnd);
    if (from.format("Y-M-D") === end.format("Y-M-D")) {
        return dateInd(dateFrom);
    }
    if (from.format("Y-m") === end.format("Y-m")) {
        return `${from.locale("id").format("Do")}-${end
            .locale("id")
            .format("Do MMMM YYYY")}`;
    }
    if (from.format("Y") === end.format("Y")) {
        return `${from.locale("id").format("Do MMMM")} - ${dateInd(dateEnd)}`;
    }
    return `${dateInd(dateFrom)} - ${dateInd(dateEnd)}`;
}

export function formatDateTrainingFull(
    dateFrom: string | Date,
    dateEnd: string | Date,
): string {
    const from = moment(dateFrom);
    const end = moment(dateEnd);
    if (from.format("Y-m-d") === end.format("Y-m-d")) {
        return dateInd(dateFrom);
    }
    if (from.format("Y-m") === end.format("Y-m")) {
        return `${from.locale("id").format("Do MMMM")}-${end
            .locale("id")
            .format("Do MMMM YYYY")}`;
    }
    if (from.format("Y") === end.format("Y")) {
        return `${from.locale("id").format("Do MMMM")} - ${dateInd(dateEnd)}`;
    }
    return `${dateInd(dateFrom)} - ${dateInd(dateEnd)}`;
}

export function trainerRatings(
    trainerRatings: Array<{
        penyampaian_rating: number;
    }>,
): string {
    let total = 0;
    let count = 0;

    for (let i = 0; i < trainerRatings.length; i++) {
        const rating = trainerRatings[i];
        if (rating.penyampaian_rating >= 1 && rating.penyampaian_rating <= 4) {
            total += rating.penyampaian_rating;
            count++;
        }
    }

    if (count > 0) {
        const average = total / count;
        return average.toFixed(2);
    }

    return (0).toFixed(2);
}

export function dateInd(value: string | Date): string {
    return moment(value).locale("id").format("Do MMMM YYYY");
}

export function dateTimeInd(value: string | Date): string {
    return moment(value).locale("id").format("Do MMMM YYYY HH:mm");
}

export function IDR(
    rawNumber: number,
    options: Pick<
        Intl.NumberFormatOptions,
        "currencyDisplay" | "minimumFractionDigits" | "maximumFractionDigits"
    > = {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        currencyDisplay: "symbol",
    },
): string {
    let number = rawNumber;
    if (typeof number === "string") {
        number = Number.parseFloat(number);
    }

    if (Number.isNaN(number) || number === null || number === undefined) {
        number = 0;
    }

    // biome-ignore lint/style/noNonNullAssertion: it's safe because we've already have a default value
    if (options.maximumFractionDigits! < options.minimumFractionDigits!) {
        throw new Error(
            "minimumFractionDigits cannot be greater than maximumFractionDigits",
        );
    }

    return number.toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
        maximumFractionDigits:
            options.maximumFractionDigits ?? options.minimumFractionDigits ?? 0,
        minimumFractionDigits: options.minimumFractionDigits ?? 0,
        useGrouping: true,
        currencyDisplay: options.currencyDisplay ?? "symbol",
        roundingMode: "trunc",
    });
}

export function moneyFormat(angka: number, separator = "."): string {
    if (!angka) {
        return "0";
    }
    let rupiah = "";
    const angkarev = angka.toString().split("").reverse().join("");
    for (let i = 0; i < angkarev.length; i++)
        if (i % 3 === 0) rupiah += angkarev.substring(i, 3) + separator;
    return rupiah
        .split("", rupiah.length - 1)
        .reverse()
        .join("");
}

/**
 * Format number to KPI format
 */

export function formatNumberKPI(value: number): string {
    if (value.toString().includes(".")) {
        return value;
    }

    return new Intl.NumberFormat("id-ID", { useGrouping: true }).format(value);
}

/**
 * Generate training certificate code
 */
export function trainingCertificate(
    dateFrom: string,
    dateEnd: string,
    productId: string,
    participantId: string,
): string {
    const dateBegin = moment(dateFrom);
    const dateEnded = moment(dateEnd);
    return `${
        dateBegin.year() +
        dateBegin.format("MM") +
        dateBegin.format("DD") +
        dateEnded.format("DD")
    }/#${productId}/${participantId}`;
}

export function unserializePeserta(serializedIdPeserta: string) {
    return phpUnserialize(serializedIdPeserta);
}

export function isPastOverdue(targetDate: string, valueDate: string) {
    const date1 = moment(targetDate);
    const date2 = moment(valueDate);
    return date2.isAfter(date1);
}

export function toTrueOrFalse(value: number) {
    switch (value) {
        case 0:
            return { value: 0, label: "Salah" };
        case 1:
            return { value: 1, label: "Benar" };
        default:
            break;
    }
}

export function toQuizTimeLimit(time: number) {
    switch (time) {
        case 15:
            return { value: 15, label: "15 detik" };
        case 30:
            return { value: 30, label: "30 detik" };
        case 45:
            return { value: 45, label: "45 detik" };
        case 60:
            return { value: 60, label: "1 menit 0 detik" };
        case 75:
            return { value: 75, label: "1 menit 15 detik" };
        case 90:
            return { value: 90, label: "1 menit 30 detik" };
        case 105:
            return { value: 105, label: "1 menit 45 detik" };
        case 120:
            return { value: 120, label: "2 menit 0 detik" };
        case 135:
            return { value: 135, label: "2 menit 15 detik" };
        case 150:
            return { value: 150, label: "2 menit 30 detik" };
        default:
            break;
    }
}

/**
 * Formats bytes to human readable format (KB, MB, GB, etc)
 */
function convertBytesToReadable(bytes: number): string {
    if (bytes === 0) return "0 Byte";
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${Math.round(bytes / 1024 ** i)} ${sizes[i]}`;
}

/**
 * Debounce function execution until specified time has passed
 */
function debounce<TParam>(func: (...args: TParam[]) => void, wait: number) {
    let timeout: number | null = null;
    return function (...args: TParam[]) {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            // @ts-expect-error: ignore this for now
            func.apply(this, args);
        }, wait);
    };
}

function slugify(value: string) {
    return String(value)
        .normalize("NFKD") // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/,/g, "")
        .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
        .replace(/\s+/g, "-") // replace spaces with hyphens
        .replace(/-+/g, "-"); // remove consecutive hyphens
}

function numbersToWordsIndonesia(value: number) {
    const units = [
        "",
        "SATU",
        "DUA",
        "TIGA",
        "EMPAT",
        "LIMA",
        "ENAM",
        "TUJUH",
        "DELAPAN",
        "SEMBILAN",
    ];
    const scales = ["", "RIBU", "JUTA", "MILYAR", "TRILIUN"];

    if (value === 0) return "NOL RUPIAH";
    if (value < 0) return `MINUS ${numbersToWords(Math.abs(value))}`;

    let words = "";

    for (let i = 0; i < scales.length; i++) {
        const scaleValue = Math.floor(value / 1000 ** i) % 1000;
        if (scaleValue !== 0) {
            let scaleWords = "";
            const hundreds = Math.floor(scaleValue / 100);
            const tens = Math.floor((scaleValue % 100) / 10);
            const ones = scaleValue % 10;

            if (hundreds > 0) {
                scaleWords += `${units[hundreds]} RATUS `;
            }

            if (tens === 1) {
                if (ones === 0) {
                    scaleWords += "SEPULUH ";
                } else if (ones === 1) {
                    scaleWords += "SEBELAS ";
                } else {
                    scaleWords += `${units[ones]} BELAS `;
                }
            } else if (tens > 1) {
                scaleWords += `${units[tens]} PULUH `;
            }

            if (tens !== 1 && ones > 0) {
                scaleWords += `${units[ones]} `;
            }

            scaleWords = scaleWords.trim();
            if (scaleWords !== "") {
                scaleWords += ` ${scales[i]} `;
            }

            words = scaleWords + words;
        }
    }

    words = words.trim();
    if (words === "SATU RIBU") {
        words = "SERIBU";
    }

    return `${words} RUPIAH`;
}

/**
 * Generate years range from current year to limit (backwards)
 */
function generateYearsRange(limit: number) {
    return Array.from(
        { length: new Date().getFullYear() - limit },
        (_, i) => limit + i + 1,
    )
        .map((year) => ({
            value: year,
            label: year.toString(),
        }))
        .toReversed();
}

const formatNumber = (value: number) => new Intl.NumberFormat("id-ID").format(value);
const formatCurrency = (value: number) =>
new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
}).format(value);

export const HELPERS = {
    debounce,
    truncateText,
    objectToQueryString,
    showNotificationServiceError,
    notifConfirmation,
    successNotif,
    feedbackDataTableNotfound,
    dateSearch,
    isOfflineTraining,
    formatDateTime,
    formatDate,
    formatTime,
    matchValue,
    successNotifContent,
    formatDateTraining,
    formatDateTrainingFull,
    trainerRatings,
    toQuizTimeLimit,
    toTrueOrFalse,
    IDR,
    trainingCertificate,
    showProgressService,
    unserializePeserta,
    moneyFormat,
    formatDateHuman,
    formatNumberKPI,
    dateInd,
    dateTimeInd,
    formatYear,
    isPassOverDue: isPastOverdue,
    convertBytesToReadable,
    slugify,
    numbersToWordsIndonesia,
    generateYearsRange,
    formatNumber,
    formatCurrency,
};
